import React from 'react'
import './footer.css'
import testmetLogo from '../../assets/img/testmateLogo.png'
import youtube from '../../assets/img/youtube.png'
import instagram from '../../assets/img/instagram (1).png'
import facebook from '../../assets/img/facebook (1).png'

function Footer() {
  return (
    <>
    <div style={{background:'#EAEEEE'}}>
    <div className='footerMainDiv'>
        <div className='footerTag'>
            <a href='https://testmate.in/api/package_price.php?token=6808&user_id=531&code=PKG' style={{textDecoration:'none',margin:'2px 0', color:'#006BCD'}}>Plans</a>
            <a href='https://testmate.in/terms-and-conditions.html' style={{margin:'2px 0', color:'#006BCD', textDecoration:'none'}}>Terms & Condition</a>
            {/* <a style={{margin:'2px 0', color:'#006BCD'}}>FAQ</a> */}
            <a href='https://testmate.in/privacy-policy.html' style={{margin:'2px 0 0', color:'#006BCD', textDecoration:'none'}}>Privacy Policy</a>
        </div>
        <div>
            <div>
            <img src={testmetLogo}/>
            {/* <div>
                <a>
                    <img src={instagram}/>
                    </a>
                <a>
                    <img src={facebook}/>
                    </a>
                <a>
                    <img src={youtube}/>
                    </a>
            </div> */}
            </div>
        </div>
    </div>
    </div>
   
    </>
  )
}

export default Footer