import React from 'react'
import './linkwebsite.css'
import playStore from '../../assets/img/google play.png'
import appleImage from '../../assets/img/app store.png'

function LinkWebsite() {
  return (
    <>
    <div>
        <div className='linkwebsiteContent'>
            <div className='lintConent'>
                <h3 className='tryOurFree'>Try our free version <span className='tryOurFree2'>Today</span></h3>
                <div style={{display:'flex'}}>
                    <a href='https://play.google.com/store/apps/details?id=com.teachfly'>
                    <img className='lingImage' src={playStore}/>
                        </a>
                        <a href='https://apps.apple.com/in/app/testmate-in/id1640493485'>
                        <img className='lingImage' src={appleImage}/>
                        </a>
                </div>
            </div>
        </div>
    </div>
    </>
    )
}

export default LinkWebsite