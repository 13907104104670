import React from 'react'
import './About.css'
import AboutImg from '../../assets/img/mockup2 2.png'

function About() {
  return (
    <>
    <div className='aboutMain' id='aboutId'>
        <div className='aboutContent'>
            <h2 className='contentHeader'>About <span  className='contentHeader2'>TESTMATE</span></h2>
            <div>
                <p className='contentPara'>Test Mate is a next-generation, ed-tech platform created to bring equal opportunity for every Indian student irrespective of their geographical location.<br/> 
While there are many apps in the market making learning easier, we thought there was a void where retention was concerned. Hence, the idea to build this app came forward – to give children the opportunity to check what they’ve learned, revise and improve. In short, get ready for the exams by solving test papers. 
</p>
            </div>
        </div>
        <div>
            <div className='aboutnImg'>
                <img style={{width:'100%'}} src={AboutImg}/>
            </div>
        </div>
    </div>
    </>
  )
}

export default About