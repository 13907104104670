import React from 'react'
import './navbar.css'
import log from '../assets/img/testmateLogo.png'

export default function Navbar() {
  return (
    <>
   <nav className="navbar navbar-expand-lg bg-body-tertiary">
  <div className="container-fluid navbarContainer">
    <img src={log} className='logoImageNav'/>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div className="navbar-nav">
        <a className="nav-link  navButton" aria-current="page"  href="#">Home</a>
        <a className="nav-link navButton" href="#aboutId" >About TESTMATE</a>
        <a style={{textDecoration:'none'}} className='navLogin' href="https://web.testmate.in/">
          <button className="nav-link loginBtn" >LOGIN</button>
          </a>
        
      </div>
    </div>
  </div>
</nav>
  </>
  )
}
