import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar';
import Banner from './Components/Banner/Banner';
import Container from './Components/contaniner/Container';
import About from './Components/About/About';
import LinkWebsite from './Components/LinkWebsite/LinkWebsite';
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <div className="App">
     <Navbar/>
     <Banner/>
     <Container/>
     <About/>
     <LinkWebsite/>
     <Footer/>
    </div>
  );
}

export default App;
