import React from 'react'
import Carousel from "react-multi-carousel";
import './banner.css'
import "react-multi-carousel/lib/styles.css";

import Banner1 from '../../assets/img/ban2.png'
import Banner2 from '../../assets/img/Mask group (1).png'
import Banner3 from '../../assets/img/ban3.jpg'
import Banner4 from '../../assets/img/ban1_responsive.jpg'
import Banner5 from '../../assets/img/ban2_responsive.jpg'
import Banner6 from '../../assets/img/ban3_responsive.jpg'
// import Banner7 from '../../assets/img/testmate_banner--4180x2000.jpg'
// import Banner8 from '../../assets/img/testmate_banner---390x270.jpg'

export default function Banner() {
    const sliderImg = [
      // {'img':Banner7},
        {'img':Banner1},
        {'img':Banner2},
        {'img':Banner3},
        // {'img2':Banner5},
        // {'img2':Banner6}
    ]
    const sliderImg2 = [
      // {'img2':Banner8},
        {'img2':Banner4},
        {'img2':Banner5},
        {'img2':Banner6},
    ]
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3001 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1025 },
          items: 1,
          arrows: true
        },
        tablet: {
          breakpoint: { max: 1024, min: 465 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
  return (
<>
<div>
    
    <div className='bannerMain'>
    <Carousel
          className="carouselSlider"
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          arrows={true} 
          // showDots={true}
          emulateTouch={true}
          renderButtonGroupOutside={true}
          renderDotsOutside={false} 
          // centerMode={centerMode}
          rewindWithAnimation={true}
        > 
        
     {sliderImg.map((item)=>{
          
          return(
            <>
            <a href="https://web.testmate.in/">
                <div className="sliderCon container-fluid"   >
                    <img src={item.img} style={{width:'100%'}}/>
                </div>
            </a>
                
            </>
          )
        })}
      </Carousel>
    </div>


    <div className='bannerMain2'>
    <Carousel
          className="carouselSlider"
          responsive={responsive}
          infinite={true}
          autolay={true}
          autoPlaySpeed={3000}
          arrows={true} 
          // showDots={true}
          emulateTouch={true}
          renderButtonGroupOutside={true}
          renderDotsOutside={false} 
          // centerMode={centerMode}
          rewindWithAnimation={true}
        > 
        
     {sliderImg2.map((item)=>{
          
          return(
            <>
            <a href="https://web.testmate.in/">
                <div className="sliderCon2 container-fluid"   >
                    <img src={item.img2} style={{width:'100%'}}/>
                </div>
            </a>
                
            </>
          )
        })}
      </Carousel>
    </div>


</div>
</> 
 )
}
