import React from 'react'
import './container.css'
import Content1 from '../../assets/img/Group 4.png'
import Content2 from '../../assets/img/Group 5.png'
import Content3 from '../../assets/img/Group 6.png'
import Content4 from '../../assets/img/Group 7.png'
import Content5 from '../../assets/img/Group 8.png'
import Content6 from '../../assets/img/Group 9.png'

function Container() {
  return (
    <>
    <div className='contentMiddle'>
        <div className='contentImg'>
            <img src={Content1} style={{width:'100%'}}/>
        </div>
        <div className='contentImg'>
            <img src={Content2} style={{width:'100%'}}/>
        </div>
        <div className='contentImg'>
            <img src={Content3} style={{width:'100%'}}/>
        </div>
        <div className='contentImg'>
            <img src={Content4} style={{width:'100%'}}/>
        </div>
        <div className='contentImg'>
            <img src={Content5} style={{width:'100%'}}/>
        </div>
        <div className='contentImg'>
            <img src={Content6} style={{width:'100%'}}/>
        </div>
    </div>
    </>
  )
}

export default Container